import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { SEO } from 'src/containers/SEO';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { InlineList } from 'src/components/molecules/InlineList';
import { Box } from 'src/components/atoms/Box';
import { Badge } from 'src/components/atoms/Badge';
import { Shuffler } from 'src/components/atoms/Shuffler';
import { Teaser } from 'src/components/molecules/Teaser';
import { NewsletterForm } from 'src/components/molecules/NewsletterForm';
import { Video } from 'src/components/atoms/Video';
import { SummitContainer } from 'src/components/atoms/SummitContainer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Rasa Summit 2019 – it's a wrap!" description="Many thanks to all our presenters and our sold-out crowd of 250 attendees from all over the world" mdxType="SEO" />
    <Section centered mdxType="Section">
      <h2 {...{
        "id": "presentations"
      }}>{`Presentations`}</h2>
      <Button to="https://www.youtube.com/playlist?list=PL75e0qA87dlFqq1FYDsfq8VZEYLEWNeVF" mdxType="Button">
  Watch the full YouTube playlist →
      </Button>
    </Section>
    <SummitContainer mdxType="SummitContainer">
      <Section centered noPadding mdxType="Section">
        <Grid columns="1fr 1fr 1fr" mdxType="Grid">
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-alan-nichol-rasa-welcome-intro" header={<Video controls src="https://www.youtube.com/watch?v=s7AmAmIV4AU&showinfo=0" mdxType="Video" />} title="Opening" footer="View the slides →" mdxType="Teaser">
  Alan Nichol, Rasa
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-josh-converse-dynamic-offset-three-part-harmony-how-rasa-and-open-source-can-make-your-product-sing" header={<Video controls src="https://www.youtube.com/watch?v=SS-S3AkRRm0?rel=0" mdxType="Video" />} title="How Rasa and Open Source Can Make Your Product Sing" footer="View the slides →" mdxType="Teaser">
  Josh Converse, Dynamic Offset
          </Teaser>
          <Teaser header={<Video controls src="https://www.youtube.com/watch?v=27OYulL_jdk?rel=0" mdxType="Video" />} title="Adobe Rasa Developer Summit" mdxType="Teaser">
  Brett Butterfield, Adobe
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-william-galindez-ariaz-octesoft-dial-rasa-for-dinner-177919951" header={<Video controls src="https://www.youtube.com/watch?v=Bu_8-_NawUI?rel=0" mdxType="Video" />} title="Dial Rasa for Dinner - Chatbot Language Learning" footer="View the slides →" mdxType="Teaser">
  William Galindez Ariaz, Octesoft
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-edouard-malet-data-scientist-n26-building-scalable-chatbots-by-empowering-content-creators" header={<Video controls src="https://www.youtube.com/watch?v=7u28QgFGDp8?rel=0" mdxType="Video" />} title="Building Scalable Chatbots by Empowering Content Creators" footer="View the slides →" mdxType="Teaser">
  Edouard Malet, N26
          </Teaser>
          <Teaser header={<Video controls src="https://www.youtube.com/watch?v=C1mN6HyDuQ0?rel=0" mdxType="Video" />} title="How to Build Level 3 Assistants in Enterprises" mdxType="Teaser">
  Panel Discussion
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-bing-liu-interactive-learning-of-taskoriented-dialog-systems" header={<Video controls src="https://www.youtube.com/watch?v=eAncgjQjqlE?rel=0" mdxType="Video" />} title="Interactive Learning of Task-Oriented Dialog Systems" footer="View the slides →" mdxType="Teaser">
  Bing Liu, Facebook
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-nouha-dziri-phd-studentgoogle-ai-evaluating-coherence-in-dialogue-systems-using-entailment" header={<Video controls src="https://www.youtube.com/watch?v=wbA-EhurYaE?rel=0" mdxType="Video" />} title="Evaluating Coherence in Dialogue Systems Using Entailment" footer="View the slides →" mdxType="Teaser">
  Nouha Dziri, Google AI
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-tom-bocklisch-rasa-product-updates-from-rasa" header={<Video controls src="https://www.youtube.com/watch?v=wOIBN9YErT0?rel=0" mdxType="Video" />} title="Rasa Product Updates" footer="View the slides →" mdxType="Teaser">
  Tom Bocklisch, Rasa
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-praneeth-gubbala-nlp-engineer-sams-club-at-walmart-sams-club-conversational-ai" header={<Video controls src="https://www.youtube.com/watch?v=qcQ51hTT_7Q?rel=0" mdxType="Video" />} title="Sam's Club Conversational AI" footer="View the slides →" mdxType="Teaser">
  Praneeth Gubbala, Sam's Club at Walmart
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-nahid-alam-opleai-building-a-custom-communication-channel-for-rasa-a-case-study" header={<Video controls src="https://www.youtube.com/watch?v=kx1k1sdj0uY?rel=0" mdxType="Video" />} title="Building a Custom Communication Channel for Rasa" footer="View the slides →" mdxType="Teaser">
  Nahid Alam, Ople.ai
          </Teaser>
          <Teaser header={<Video controls src="https://www.youtube.com/watch?v=pIoWgR0NUXk?rel=0" mdxType="Video" />} title="Product Management for Assistants" mdxType="Teaser">
  Panel Discussion
          </Teaser>
          <Teaser to="https://www.slideshare.net/rasa_technologies/rasa-developer-summit-juste-petraityte-rasa-rasa-community-updates-outlook" header={<Video controls src="https://www.youtube.com/watch?v=xGkcYn4TzSg?rel=0" mdxType="Video" />} title="Rasa Community Update" footer="View the slides →" mdxType="Teaser">
  Justina Petraityte, Rasa
          </Teaser>
        </Grid>
      </Section>
      <Section centered spacious mdxType="Section">
        <h2 {...{
          "id": "rasa-summit-2019-was-sold-out"
        }}>{`Rasa Summit 2019 was SOLD OUT!`}</h2>
      </Section>
    </SummitContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      